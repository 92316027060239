import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 554.000000 554.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,554.000000) scale(0.100000,-0.100000)">
<path d="M0 2770 l0 -2770 2770 0 2770 0 0 2770 0 2770 -2770 0 -2770 0 0
-2770z m1467 1254 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-60
-10 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-68 -19 c-70 -26
-119 -56 -167 -104 -171 -171 -159 -474 32 -819 31 -57 61 -109 66 -115 6 -7
38 -50 72 -97 76 -105 189 -239 256 -304 28 -27 48 -54 45 -60 -3 -6 -3 -8 2
-4 4 4 36 -18 69 -50 89 -82 227 -197 334 -276 50 -37 92 -72 92 -77 0 -5 3
-8 8 -7 6 2 208 -126 239 -152 7 -6 15 -10 19 -10 4 0 39 -20 78 -44 123 -75
496 -246 539 -246 5 0 23 -6 40 -14 100 -45 313 -80 487 -80 198 -1 319 38
416 132 26 26 42 37 36 27 -31 -59 -149 -133 -252 -159 -155 -40 -372 -28
-600 33 -148 40 -382 126 -388 142 -2 5 -12 9 -21 9 -10 0 -60 22 -112 49 -52
26 -123 63 -159 82 -36 18 -79 43 -96 56 -17 13 -34 23 -37 23 -10 0 -141 92
-145 102 -2 4 -8 8 -14 8 -6 0 -31 15 -57 34 -32 24 -62 36 -101 41 -119 16
-257 79 -298 136 -7 10 -20 25 -28 34 -20 20 -54 107 -54 139 0 33 -20 87 -31
80 -5 -3 -6 3 -2 12 4 11 2 15 -4 11 -9 -6 -49 33 -144 139 -120 135 -284 367
-275 391 3 8 1 11 -4 8 -13 -8 -65 94 -120 240 -26 68 -50 204 -50 279 1 159
65 284 184 358 52 31 139 67 166 67 8 0 -1 -6 -21 -14z m921 -755 c35 -13 70
-28 77 -34 7 -6 30 -24 52 -41 62 -48 111 -143 111 -217 l0 -28 -130 0 -130 0
-14 34 c-20 48 -65 70 -146 70 -100 0 -160 -42 -160 -114 0 -74 32 -92 222
-120 151 -22 244 -44 262 -63 7 -6 29 -24 48 -40 92 -71 113 -256 43 -378
l-22 -39 -105 77 c-103 76 -106 79 -101 111 9 54 -38 91 -135 107 -37 6 -57
20 -146 108 -92 92 -188 202 -284 325 l-34 43 33 50 c43 68 122 126 211 155
97 32 249 30 348 -6z m912 5 c95 -20 134 -41 203 -110 82 -82 110 -158 110
-300 0 -141 -26 -207 -114 -295 -95 -95 -142 -111 -338 -118 l-153 -4 0 -145
0 -145 -92 3 c-78 2 -101 7 -140 28 l-48 26 0 532 0 532 23 4 c12 2 126 5 252
5 165 1 249 -2 297 -13z m965 -9 c171 -44 303 -169 364 -348 27 -79 37 -247
19 -315 -20 -78 -42 -148 -49 -153 -4 -3 -22 -29 -40 -58 -37 -58 -109 -126
-158 -150 -18 -9 -33 -19 -33 -23 0 -4 -37 -17 -82 -30 -77 -21 -102 -23 -338
-24 l-255 0 -2 554 c-2 304 0 557 3 563 10 16 499 3 571 -16z m-106 -1503
c-12 -20 -14 -14 -5 12 4 9 9 14 11 11 3 -2 0 -13 -6 -23z"/>
<path d="M2884 2997 c-2 -7 -3 -82 -2 -167 l3 -155 121 1 c99 0 127 3 149 18
35 23 57 77 60 151 2 52 -1 67 -24 100 -15 22 -38 45 -51 52 -35 18 -249 18
-256 0z"/>
<path d="M3840 2695 l0 -305 23 -1 c63 -3 176 3 209 12 32 8 76 37 113 74 37
37 75 138 75 200 0 109 -32 198 -92 256 -54 52 -111 69 -230 69 l-98 0 0 -305z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
